import { ApiSlice } from './ApiSlice'
import AppReducer from './App/AppSlice'
import StudentReducer from './Student/StudentSlice'
import RadioReducer from './Radio/RadioSlice'
import IndividualStudentReducer from './IndividualStudent/IndividualStudentSlice'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    [ApiSlice.reducerPath]: ApiSlice.reducer,
    app: AppReducer,
    student: StudentReducer,
    radio: RadioReducer,
    individualStudent: IndividualStudentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(ApiSlice.middleware),
  devTools: true,
})
