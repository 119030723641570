import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Flex,
  VisuallyHidden,
  Select,
} from '@chakra-ui/react'
import React from 'react'
import { UploadIcon } from '../utils/Icons'

const EditStudentModal = ({
  isOpen,
  onClose,
  student,
  handleInputChange,
  updateIsLoading,
  handleStudentUpdate,
  imageFile,
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent>
        <ModalHeader>Edit Student Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Box m={1}>
              <Text mb={1} fontWeight={'bold'}>
                Photo
              </Text>
              <Flex gap="2rem">
                <Image
                  src={
                    imageFile ? URL.createObjectURL(imageFile) : student?.image
                  }
                  w="150px"
                  h="150px"
                  borderRadius={'8px'}
                />
                <Flex
                  direction="column"
                  align="center"
                  justifyContent={'center'}
                  textAlign="center"
                  w="150px"
                  h="150px"
                  _hover={{ cursor: 'pointer' }}
                  color="black"
                  borderRadius={'8px'}
                  htmlFor="file-upload"
                  as="label"
                >
                  <UploadIcon />
                  <VisuallyHidden>
                    <input
                      name="image"
                      id="file-upload"
                      type="file"
                      //   onInput={(e) => handleFileChange(e)}
                      onChange={handleInputChange}
                      accept="image/jpeg, image/png, image/jpg"
                    />
                  </VisuallyHidden>
                  Upload New Image
                </Flex>
              </Flex>
            </Box>
            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                Name
              </Text>
              <Input
                value={student?.name}
                name={'name'}
                onChange={handleInputChange}
                placeholder={'Name'}
              />
            </Box>
            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                First Sem
              </Text>
              <Input
                value={student?.firstSem}
                name={'firstSem'}
                onChange={handleInputChange}
                placeholder={'First Semester Marks'}
              />
            </Box>

            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                Second Sem
              </Text>
              <Input
                value={student?.secondSem}
                name={'secondSem'}
                onChange={handleInputChange}
                placeholder={'Second Semester Marks'}
              />
            </Box>

            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                Final Status
              </Text>
              <Select
                value={student?.finalStatus}
                name={'finalStatus'}
                onChange={handleInputChange}
              >
                <option value="PASS">PASS</option>
                <option value="FAIL">FAIL</option>
                <option value="SECOND SEM">SECOND SEM</option>
              </Select>
            </Box>
            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                Enrollment No
              </Text>
              <Input
                isDisabled
                value={student?.enrollmentNo}
                name={'enrollmentNo'}
                onChange={handleInputChange}
                placeholder={'Enrollment No'}
              />
            </Box>
            <Box m={1} pt="1rem">
              <Text mb={1} fontWeight={'bold'}>
                Email
              </Text>
              <Input
                value={student?.email}
                name={'email'}
                onChange={handleInputChange}
                placeholder={'Email'}
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={updateIsLoading}
            onClick={handleStudentUpdate}
            bg={'#6C3B1C'}
            color="white"
            mr={2}
          >
            Update
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EditStudentModal
