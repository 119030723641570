import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Input,
  Stack,
  RadioGroup,
  Radio,
  Select,
} from '@chakra-ui/react'
import StudentService from '../apiServices/student.service'
import { camelToTitle, courseInfoInputFields } from '../utils/constants.js'
import CustomInput from '../components/CustomInput.jsx'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../store/App/AppSlice.js'

const UploadConfirmModal = (props) => {
  const user = useSelector(selectCurrentUser)
  const [courses, setCourses] = useState([])
  const [batches, setBatches] = useState([])
  const [batchName, setBatchName] = useState("")
  const handleRadioChange = (value) => {
    setIsPrev(value === "2" ? false : true)
  }
  useEffect(() => {
    (async () => {
      const { data } = await StudentService.getPreviousBatchesList()
      if (data.status === true) {
        setBatches(data?.data.filter((batch) => batch.place == user?.location).map(batch => ({ label: batch.batchName, value: batch._id })))
      }
    })()
  }, [])
  const getCourses = async () => {
    const { data } = await StudentService.getCourses()
    if (data.status === true) {
      setCourses(data?.data)
      handleInputChange("courseId", data?.data[data?.data.findIndex(course => course.courseTag === localStorage.getItem('courseTag'))]._id)
    }
  }
  useEffect(() => {
    getCourses()
  }, [])

  const { isOpen, onClose, handleInputChange, handleUploadStudents, isLoading, isPrev, setIsPrev, setPrevBatch } = props
  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>Course & Session</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RadioGroup onChange={handleRadioChange} value={isPrev ? "1" : "2"}>
              <Stack spacing={5} direction="row">
                <Radio colorScheme="green" value={"1"}>
                  New Batch
                </Radio>
                <Radio colorScheme="green" value={"2"}>
                  Previous Batch
                </Radio>
              </Stack>
            </RadioGroup>
            <Text my={2}>Course Type</Text>
            {!isPrev && <Select
              my={5}
              placeholder={"Select Previous Batch..."}
              onChange={(event) => {
                setPrevBatch(event.target.value)
                console.log(event.target.value)
                const batch = batches.find(batch => batch.value === event.target.value);
                setBatchName(batch.label)
              }}
            >
              {batches.map(option =>
                <option value={option.value}>{option.label}</option>
              )}
            </Select>}
            <Input disabled={true} value={courses[courses.findIndex(course => course.courseTag === localStorage.getItem('courseTag'))]?.courseName} />
            {isPrev && <>
              {Object.entries(courseInfoInputFields[localStorage.getItem("courseTag")]).map(([key, value]) => (
                <Box mt={5}>
                  <Text as={'label'}>{camelToTitle(key)}</Text>
                  <CustomInput name={key} isPrev={isPrev} batchName={batchName} handleEditInputChange={handleInputChange} data={{}} {...value} />
                </Box>
              ))}
            </>}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                handleUploadStudents()
                // onClose()
              }}
              bg="#6C3B1C"
              color="white"
              mr={2}
              isLoading={isLoading}
            >
              Upload
            </Button>
            <Button
            // onClick={onClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default UploadConfirmModal
