import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { PiCertificate } from 'react-icons/pi'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  useToast,
  Thead,
  Spinner,
  Flex,
  Link,
  Image,
  TableContainer,
} from '@chakra-ui/react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkAllStudentCheckedStatus,
  selectCheckedAll,
  selectCheckedIds,
  selectCurrentStudents,
  setCurrentStudents,
  setUploadedStudents,
  updateCurrentStudent,
  clearCheckedIds,
} from '../store/Student/StudentSlice'
import { CiUser } from 'react-icons/ci'
import readXlsxFile from 'read-excel-file'
import StudentService from '../apiServices/student.service'
import { useNavigate } from 'react-router-dom'
import EditStudentModal from '../modals/EditStudentModal'
import { IoRefresh } from 'react-icons/io5'
import BatchListing from '../components/BatchListing'
import { camelCaseToSpaced } from '../utils/constants'
import routes from '../utils/routes'
import { displayFields, requiredFields } from '../utils/excelValidators'

const Batches = () => {
  const itemsPerPage = 10
  const toast = useToast()
  const inputRef = useRef(null)
  const dialogRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [types, setTypes] = useState([])
  const [courseId, setCourseId] = useState('')
  const [student, setStudent] = useState(null)
  const [courseTag, setCourseTag] = useState('')
  const [activePage, setActivePage] = useState(0)
  const checkedIds = useSelector(selectCheckedIds)
  const [imageFile, setImageFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const students = useSelector(selectCurrentStudents)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [checkedState, setCheckedState] = useState({})
  const checkedAllStatus = useSelector(selectCheckedAll)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentBatchId, setCurrentBatchId] = useState('')
  const [globalFiltering, setGlobalFiltering] = useState('')
  const [studentLoading, setStudentLoading] = useState(false)
  const [updateIsLoading, setUpdateIsLoading] = useState(false)
  const [isSecondCertificateDialog, setIsSecondCertificateDialog] =
    useState(false)

  const filterData = (data, filter) => {
    if (!filter.trim()) return data
    return data.filter((student) =>
      Object.values(student).some((value) =>
        String(value).toLowerCase().includes(filter.toLowerCase())
      )
    )
  }

  const filteredData = useMemo(
    () => filterData(students, globalFiltering),
    [students, globalFiltering]
  )

  const currentPageData = useMemo(() => {
    const start = activePage * itemsPerPage
    const end = start + itemsPerPage
    return filteredData.slice(start, end)
  }, [filteredData, activePage, itemsPerPage])

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)

  const getCurrentYearStudents = async (payload) => {
    if (!payload?.id) {
      return
    }
    setStudentLoading(true)
    StudentService.getCurrentStudents(payload).then(({ data }) => {
      if (data.data && data.data.length > 0) {
        setCourseId(data.data[0].courseId)
        setCourseTag(data.data[0].courseId?.courseTag)
        console.log('courseTag', data.data[0].courseId?.courseTag)
        setTypes(displayFields[data.data[0].courseId?.courseTag])
        if (data.status === true) {
          dispatch(
            setCurrentStudents(
              data.data.map((student) => {
                return {
                  ...student,
                  checked: false,
                }
              })
            )
          )
        }
      }
      setStudentLoading(false)
    })
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleCertificateCloseDialog = () => {
    setIsSecondCertificateDialog(false)
  }

  const handleItemCheck = (cell) => {
    console.log(cell.row.original._id)
    const enrollmentNo = cell.row.original._id
    const { _id } = cell.row.original
    setCheckedState((prev) => ({ ...prev, [_id]: !prev[_id] }))
    dispatch(updateCurrentStudent({ enrollmentNo }))
  }

  const handleAllItemCheck = () => {
    dispatch(checkAllStudentCheckedStatus())
  }

  const handleUpdateModalInputChange = (e) => {
    if (e.target.name === 'image') {
      const file = e.target.files[0]
      if (!file) return
      if (/(image\/jpeg)|(image\/png)/i.test(file.type)) {
        setImageFile(file)
        setStudent({ ...student, [e.target.name]: file })
      } else {
        toast({
          title: 'Unsupported file type.',
          description: 'Only JPG, JPEG, and PNG files can be uploaded.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
    } else {
      setStudent({ ...student, [e.target.name]: e.target.value })
    }
  }

  const handleConfirmUpload = () => {
    setIsDialogOpen(false)
    if (!inputRef?.current.files[0]) {
      toast({
        title: 'Error',
        description: 'Please select a file for upload.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    const fileName = inputRef.current.files[0].name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const allowedFileTypes = ['xls', 'xlsx', 'xlsm']
    if (!allowedFileTypes.includes(fileExtension)) {
      toast({
        title: 'Error',
        description:
          'Only XLS, XLSX, and XLSM file types are allowed for upload.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }
    readXlsxFile(inputRef?.current.files[0]).then((rows) => {
      let filteredStudents = []
      rows.slice(1).forEach((student, index) => {
        let studentObj = {
          index: index + 1,
          name: student[1],
          enrollmentNo: student[2],
          email: student[3],
          firstSem: student[4],
          secondSem: student[5],
          finalStatus: student[6],
        }
        if (
          studentObj.name &&
          studentObj.email &&
          studentObj.enrollmentNo &&
          studentObj.name.trim() !== '' &&
          studentObj.email.trim() !== '' &&
          studentObj.enrollmentNo.trim() !== ''
        ) {
          console.log(studentObj)
          filteredStudents.push(studentObj)
        }
      })
      dispatch(setUploadedStudents(filteredStudents))
      navigate(routes.UploadStudents)
    })
  }

  const handleGenerateCertificate = async () => {
    setIsLoading(true)
    try {
      const response = await StudentService.generateCertificate({
        marksheet: checkedIds,
        courseId: courseId?._id,
      })
      if (response.data.status === true) {
        toast({
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
        dispatch(clearCheckedIds())
      }
    } catch (e) {
      toast({
        title: 'Error',
        description: e?.message ?? 'Something went wrong please try later!',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
      getCurrentYearStudents({ id: currentBatchId }).then()
    }
  }

  const handleGenerateCertificateIndividual = async () => {
    setIsSecondCertificateDialog(false)
    setIsLoading(true)
    try {
      const response = await StudentService.generateCertificate({
        // marksheet: [individualStudentDetails?._id],
        courseId,
      })
      if (response.data.status === true) {
        toast({
          title: 'Success',
          description: response.data.data,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
    } catch (e) {
      toast({
        title: 'Error',
        description: e.message ?? 'Something went wrong please try later!',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleStudentUpdate = async () => {
    setUpdateIsLoading(true)
    try {
      const [updateStudentResponse, updateMarksheetResponse] =
        await Promise.all([
          StudentService.updateStudent(student),
          StudentService.updateMarksheets({
            id: student.marksheetId,
            firstSem: student.firstSem,
            secondSem: student.secondSem,
            finalStatus: student.finalStatus,
          }),
        ])
      if (updateStudentResponse?.data?.status === true) {
        toast({
          title: 'Success',
          description: updateStudentResponse?.data?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      } else {
        toast({
          title: 'Error',
          description: updateStudentResponse?.response?.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
      if (updateMarksheetResponse?.data?.status === true) {
        await getCurrentYearStudents({ id: currentBatchId })
        toast({
          title: 'Success',
          description: updateMarksheetResponse?.data?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Error',
          description: updateMarksheetResponse?.response?.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
    } catch (e) {
      toast({
        title: 'Error',
        description: e?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      })
    } finally {
      setUpdateIsLoading(false)
      onClose()
      setImageFile(null)
    }
  }

  const columns = []
  columns.push({
    id: '1',
    header: () => (
      <Checkbox
        isChecked={checkedAllStatus}
        onChange={handleAllItemCheck}
      ></Checkbox>
    ),
    cell: (cell) => {
      return (
        <Box>
          <Checkbox
            isChecked={cell.cell.row.original.checked}
            onChange={() => handleItemCheck(cell)}
          />
        </Box>
      )
    },
  })

  types.map((row, idx) =>
    row === 'image'
      ? columns.push({
          id: '1',
          priority: 0,
          header: 'Image',
          cell: (cell) => (
            <Box>
              {cell?.row?.original?.image ? (
                <Image
                  src={cell?.row?.original?.image}
                  w="50px"
                  h="50px"
                  borderRadius={'5px'}
                />
              ) : (
                <CiUser size={45} />
              )}
            </Box>
          ),
        })
      : columns.push({ header: camelCaseToSpaced(row), accessorKey: row })
  )

  columns.push({
    id: '1',
    header: 'Download',
    cell: (cell) => (
      <>
        {cell.row.original.certificateLink !== '' ? (
          <Link
            href={cell.row.original.certificateLink}
            bg={'green'}
            py={2}
            px={4}
            borderRadius={10}
            color={'#fff'}
            textDecor={'none'}
            target="_blank"
          >
            Download
          </Link>
        ) : (
          'NA'
        )}
      </>
    ),
  })

  const table = useReactTable({
    data: currentPageData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  useEffect(() => {
    getCurrentYearStudents({ id: currentBatchId }).then()
  }, [currentBatchId])

  useEffect(() => {
    const newCheckedState = {}
    students.forEach((student) => {
      newCheckedState[student._id] = !!checkedState[student._id]
    })
    setCheckedState(newCheckedState)
  }, [students])

  useEffect(() => {
    if (activePage > totalPages - 1) {
      setActivePage(Math.max(0, totalPages - 1))
    }
  }, [totalPages, activePage])

  return (
    <Box>
      <Fragment>
        <Text fontSize={24} fontWeight={800}>
          Batches
        </Text>
        <BatchListing
          currentBatchId={currentBatchId}
          setCurrentBatchId={setCurrentBatchId}
          setCourseTag={setCourseTag}
        />
        <Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
          <Input
            type={'text'}
            w={275}
            value={globalFiltering}
            onChange={(e) => {
              setGlobalFiltering(e.target.value)
            }}
            h={'50px'}
            fontSize={15}
            placeholder={'Search by name...'}
          />
        </Box>
        {!studentLoading ? (
          <TableContainer>
            <Table mt="1rem" overflow={'scroll'}>
              <Thead>
                {table.getHeaderGroups().map((headerGroup, idx) => (
                  <Tr key={idx}>
                    {headerGroup.headers.map((header, idx) => (
                      <Th key={idx}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row, idx) => (
                  <Tr
                    key={idx}
                    bg={
                      checkedAllStatus
                        ? '#F0F8FF'
                        : checkedIds?.includes(row.original.id)
                          ? '#F0F8FF'
                          : 'none'
                    }
                  >
                    {row.getVisibleCells().map((cell, idx) => (
                      <Td key={idx}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            backgroundColor="rgba(255, 255, 255, 0.8)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            zIndex="9999"
          >
            <Flex align="center" justify="center" flexDir="column">
              <Spinner size="xl" thickness="5px" speed="0.5s" color="#6C3B1C" />
              <Text fontWeight="bold" mt="1rem" color="#6C3B1C">
                Loading...
              </Text>
            </Flex>
          </Box>
        )}
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box></Box>
          <Box display={'flex'} alignItems={'center'}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="2"
              mb="2"
            >
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() =>
                  setActivePage((prevPage) => Math.max(0, prevPage - 1))
                }
                isDisabled={activePage === 0}
              >
                <FaCaretLeft />
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index}
                  size="sm"
                  colorScheme={activePage === index ? 'blue' : 'gray'}
                  onClick={() => setActivePage(index)}
                  mx="5px"
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="blue"
                size="sm"
                onClick={() =>
                  setActivePage((prevPage) =>
                    Math.min(totalPages - 1, prevPage + 1)
                  )
                }
                isDisabled={activePage === totalPages - 1}
                ml={2}
              >
                Next
                <FaCaretRight />
              </Button>
            </Box>
          </Box>
          <Box>
            <Button
              mt={2}
              mb={2}
              colorScheme={'green'}
              onClick={handleGenerateCertificate}
              isLoading={isLoading}
            >
              <PiCertificate size={24} />
              <Text ml={2}>Generate Certificate</Text>
            </Button>
            <Button
              ml={2}
              colorScheme={'green'}
              onClick={() => getCurrentYearStudents({ id: currentBatchId })}
              isLoading={isLoading}
            >
              <IoRefresh size={24} />
              <Text ml={2}>Refresh</Text>
            </Button>
          </Box>
        </Box>
      </Fragment>
      <EditStudentModal
        isOpen={isOpen}
        onClose={onClose}
        student={student}
        updateIsLoading={updateIsLoading}
        handleStudentUpdate={handleStudentUpdate}
        handleInputChange={handleUpdateModalInputChange}
        imageFile={imageFile}
      />
      <AlertDialog
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        leastDestructiveRef={dialogRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Upload Confirmation</AlertDialogHeader>
          <AlertDialogBody>Do you want to upload the file?</AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              bg="#6C3B1C"
              _hover={{ bg: '#6C3B1C' }}
              ml={3}
              onClick={handleConfirmUpload}
              color="white"
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={isSecondCertificateDialog}
        onClose={handleCertificateCloseDialog}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Submit Confirmation</AlertDialogHeader>
          <AlertDialogBody>
            Do you want to generate certificate for this student: ?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleCertificateCloseDialog}>Cancel</Button>
            <Button
              bg="#6C3B1C"
              _hover={{ bg: '#6C3B1C' }}
              ml={3}
              onClick={handleGenerateCertificateIndividual}
              color="white"
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  )
}

export default Batches
