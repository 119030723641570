const routes = {
  Home: '/',
  Overview: '/overview',
  UploadStudents: '/upload-students',
  Batches: '/batches',
  Signin: '/signin',
  Otp: '/otp',
  StudentForm: '/student/form',
  StudentVerify: '/student/verify/:id',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password/:resetToken',
  PageNotFound: '/*'
}

export default routes
