import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: '',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // const token = getState().auth.token
    // if (token) {
    // headers.set('Authorization', `Bearer ${token}`)
    // }
    headers.set('Accept', 'application/json')
    headers.set('Content-Type', 'application/json')
    return headers
  },
})

const baseQueryWarpper = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  // const { getState } = api
  // const state = getState()

  // let data = result.data

  // if (
  //     ['Invalid OTP', 'Invalid session', 'invalid sign'].includes(
  //         data?.message
  //     )
  // ) {
  //     api.dispatch(
  //         setToast({ message: 'Unauthorized action', type: 'danger' })
  //     )
  // }
  // if (data?.message === 'Token expired') {
  //     const refreshResponse = await baseQuery('/refresh', api, extraOptions)
  //     if (refreshResponse?.status === true) {
  //         // const user = state.auth.user;
  //         // store the new token
  //         api.dispatch(setToken(refreshResponse?.token))
  //         // retry the original with new token
  //         result = await baseQuery(args, api, extraOptions)
  //     } else {
  //         // api.dispatch(logout());
  //     }
  // }
  // if (args.notification === true && data?.status === true) {
  //     api.dispatch(setToast({ message: data.message, type: data.status }))
  // }

  return result
}

export const ApiSlice = createApi({
  baseQuery: baseQueryWarpper,
  // global configuration for the api
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({}),
})
