import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  radioValue: '1',
}

export const RadioSlice = createSlice({
  name: 'radio',
  initialState,
  reducers: {
    setRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
  },
})

export const { setRadioValue } = RadioSlice.actions
export default RadioSlice.reducer
export const selectRadioValue = (state) => state.radio.radioValue
