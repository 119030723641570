// Handle Auth related services
import axios from './customAxios'
// import axios from 'axios'

class AuthService {
  /**
   * Sign in function that sends a POST request to the login API endpoint for user authentication.
   * @param {string} email - The email of the user attempting to sign in.
   * @param {string} password - The password associated with the user's account.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  static async signIn(email, password) {
    // Construct the URL for the login API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/signin`

    // Make a POST request using the customAxios instance
    // Include the email and password in the request body for authentication
    return axios().post(url, { email, password })
  }
  /**
   * Resend function that sends a POST request to the resend otp API endpoint.
   * @param {string} email - The email of the user attempting to send the otp.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  static async resendOtp(email) {
    // Construct the URL for the login API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/resend-otp`

    // Make a POST request using the axios instance
    // Include the email and password in the request body for authentication
    return axios().post(url, { email })
  }

  // verify otp
  /**
   * Verify OTP (One-Time Password) for user authentication.
   * @param {string} email - The email of the user for which OTP verification is requested.
   * @param {string} otp - The One-Time Password to be verified.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  static async verifyOTP(email, otp) {
    // Construct the URL for the verify OTP API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/verify-otp`

    // Make a POST request using the axios instance
    // Include the email and OTP in the request body for verification
    // Use { withCredentials: true } to allow sending cookies (if applicable)
    return axios().post(url, { email, otp }, { withCredentials: true }) //{ withCredentials: true }
  }

  /**
   * Sends a request to initiate the password reset process.
   * @param {string} username - The username for which the password reset is requested.
   * @returns {Object} - An object representing the response data, or an empty object if an error occurs.
   */
  static async forgotPassword(email) {
    // Construct the URL for the password reset API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/forgot-password`

    try {
      // Send a POST request to the password reset endpoint with the provided email
      const response = await axios().post(url, { email })

      // Handle response data or return it if needed
      return response || {}
    } catch (error) {
      // Log an error message if the request fails
      console.error('Error in forgotPassword:', error)

      // Handle errors or return a default value
      return {}
    }
  }

  /**
   * Sends a request to initiate the password reset process.
   * @param {string} username - The username for which the password reset is requested.
   * @param {string} resetToken - The unique resetToken received by the user for resetpassword request.
   * @param {string} password - The new password which user wants to set to his profile.
   * @returns {Object} - An object representing the response data, or an empty object if an error occurs.
   */

  static async resetPassword(email, resetToken, password) {
    // Construct the URL for the password reset API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/reset-password`

    try {
      // Send a POST request to the password reset endpoint with the provided email
      const response = await axios().post(url, {
        email,
        resetToken,
        password,
      })

      // Handle response data or return it if needed
      return response || {}
    } catch (error) {
      // Log an error message if the request fails
      console.error('Error in forgotPassword:', error)

      // Handle errors or return a default value
      return {}
    }
  }

  /**
   * Sign out function that sends a POST request to the logout API endpoint.
   * @param {string} token - The authentication token to be included in the request body.
   * @returns {Promise} A promise that resolves with the response data from the server.
   */
  static async signOut(token) {
    // Construct the URL for the logout API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/auth/logout`

    // Make a POST request using the axios instance
    // Include the token in the request body for proper authentication
    return axios().post(url, { token })
  }

  /**
   * Sign out function that sends a POST request to the logout API endpoint.
   * @returns {Promise} A promise that resolves with the response data from the server.
   * @param email
   */
  static async getAuthUser(email) {
    // Construct the URL for the logout API endpoint
    const url = `${process.env.REACT_APP_API_HOST}/api/user?email=${email}`

    // Make a POST request using the axios instance
    // Include the token in the request body for proper authentication
    return axios().get(url)
  }

  /**
   * Refreshes the access token by sending a request to the backend server.
   * The new access token is stored in the local storage.
   * @returns {Object} Response data containing the new access token.
   */
  static async refreshAccessToken() {
    try {
      // Construct the URL for the refresh access token endpoint
      const url = `${process.env.REACT_APP_API_HOST}/api/auth/refresh-accesstoken`

      // Send a POST request to the refresh access token endpoint
      const response = await axios().post(url, {}, { withCredentials: true })

      // Store the new access token in the local storage
      localStorage.setItem('token', response?.data?.data)

      // Return the response data containing the new access token
      return response?.data
    } catch (err) {
      // Return the error if there is any
      return err
    }
  }
}

export default AuthService
