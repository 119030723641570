import React from 'react'
import { Link } from 'react-router-dom'
import { IoArrowBackCircle } from 'react-icons/io5'

const PageNotFound = () => {
  return (
    <div className="fof">
      <h1>Error 404</h1>
      <Link
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        to={'/'}
      >
        <IoArrowBackCircle size={24} />
        <p style={{ marginLeft: '5px' }}>Go back to Home</p>
      </Link>
    </div>
  )
}
export default PageNotFound
