import React, { Fragment, useEffect, useRef, useState } from 'react'
import {
  Input,
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  Image,
  HStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import AuthService from '../apiServices/auth.service'
import { RESPONSE_STATUS } from '../utils/constants'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useToast } from '@chakra-ui/react'
import { setAuth } from '../store/App/AppSlice'
import { jwtDecode } from 'jwt-decode'
import { CustomAxios } from '../apiServices/customAxios'
import { logo } from '../utils/images'
import routes from '../utils/routes'

const VerifyOtp = () => {
  const toast = useToast()
  const resendOtpFormRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [resendIsLoading, setResendIsLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isOTPVisible, setIsOTPVisible] = useState('')

  const handleVerifyOpt = async (values) => {
    const email = localStorage.getItem('email')
    const otp = values.otp
    try {
      setIsLoading(true)
      const response = await AuthService.verifyOTP(email, otp)
      if (response.data.status === RESPONSE_STATUS.SUCCESS) {
        localStorage.setItem('token', response.data.token)
        CustomAxios.setHeaders()
        const decoded = jwtDecode(response.data.token)
        const email = decoded?.email
        const { data } = await AuthService.getAuthUser(email)
        if (data.status === true) {
          navigate(routes.Home)
          setIsLoading(false)
          dispatch(setAuth({ loggedIn: true, user: data.data[0] }))
        }
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err?.response?.data?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
      setIsLoading(false)
    }
  }

  const handleResendOtp = async () => {
    setResendIsLoading(true)
    const email = localStorage.getItem('email')
    const response = await AuthService.resendOtp(email)
    if (response.data.status === true) {
      toast({
        title: 'Success',
        description: "We've sent the otp on your email.",
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    }
    setResendIsLoading(false)
  }

  useEffect(() => {
    const email = localStorage.getItem('email')
    if (!email) {
      navigate(-1)
    }
  })

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Card
          align="center"
          size={{ base: 'sm', md: 'md' }}
          width={{ base: 'container.xs', md: 'container.sm' }}
          padding="9"
          rounded="md"
          boxShadow="4px 6px 10px 0px rgba(0, 0, 0, 0.10)"
        >
          <Image src={logo} w={'100px'} h={'100px'} />
          <CardHeader>
            <Heading size={{ base: 'sm', md: 'md' }} as="b">
              OTP Verification
            </Heading>
          </CardHeader>

          <Text size={{ base: 'xs', md: 'sm' }}>
            {' '}
            We sent OTP code on your email address{' '}
          </Text>
          <CardBody>
            <Formik
              innerRef={resendOtpFormRef}
              initialValues={{
                otp: '',
              }}
              onSubmit={handleVerifyOpt}
              validationSchema={Yup.object().shape({
                otp: Yup.string().required('OTP is required'),
              })}
            >
              {(props) => {
                const {
                  touched,
                  errors,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                } = props
                return (
                  <Fragment>
                    <Form>
                      <Box marginTop={'10px'} position="relative">
                        <Box>
                          <HStack display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <PinInput
                              name='otp'
                              onChange={(pin) => setFieldValue('otp', pin)}
                              onBlur={handleBlur}
                              _focusVisible={
                                errors.otp && touched.otp
                                  ? {
                                    border: '1px solid red !important',
                                  }
                                  : { border: '1px solid #6C3B1C' }
                              }
                              placeholder='*'
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                          {/* <Input
                            type='text'
                            color={'#141D43'}
                            marginTop="10px"
                            w={{ base: '15rem', md: '28rem', lg: '28rem' }}
                            id="otp"
                            bg={'#fff'}
                            zIndex="10"
                            placeholder="Enter OTP"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            border="1px solid #9E9E9E"
                            fontSize={'14px'}
                            _placeholder={{
                              opacity: 1,
                              color: '',
                              fontSize: '14px',
                            }}
                            _focusVisible={
                              errors.otp && touched.otp
                                ? {
                                  border: '1px solid red !important',
                                }
                                : { border: '1px solid #6C3B1C' }
                            }
                          /> */}
                          {errors.otp && touched.otp && (
                            <Box color="red.500" marginTop={'10px'}>
                              {errors.otp}
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Button
                        bg="#6C3B1C"
                        color="white"
                        w={{ base: '15rem', md: '28rem' }}
                        onClick={() => handleSubmit()}
                        marginTop={'2rem'}
                        position="relative"
                        isLoading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form>
                  </Fragment>
                )
              }}
            </Formik>
          </CardBody>
          <Text size={{ base: 'xs', md: 'sm' }}>
            Didn't receive the code ?{' '}
            <Button
              color="#6C3B1C"
              variant="link"
              size={{ base: 'xs', md: 'sm' }}
              onClick={() => handleResendOtp()}
              isLoading={resendIsLoading}
            >
              Resend
            </Button>
          </Text>
        </Card>
      </Box>
    </Fragment>
  )
}

export default VerifyOtp
