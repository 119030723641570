import { Flex, Box, Text, Button } from '@chakra-ui/react'
import { useEffect, useState, useContext } from 'react'
import { motion, useAnimation } from 'framer-motion'
import VerificationCardDetails from './VerificationCardDetails'
import VerificationCardHeader from './VerificationCardHeader'

const VerificationCard = ({
  id,
  studentIntegrityData,
  steps,
  activeStep,
  setActiveStep,
  dataFetched,
}) => {
  const containerControls = useAnimation()
  const contentControls = useAnimation()
  const [isCtaTriggered, setIsCtaTriggered] = useState(false)
  const [headerTitle, setHeaderTitle] = useState('')

  const commonButtonStyles = {
    width: '100%',
    padding: '1.5rem',
    fontWeight: '400',
  }

  // useEffect(() => {
  //   if (!isCtaTriggered) {
  //     ;(async () => {
  //       await containerControls.start({ height: 'auto' })
  //       await contentControls.start({ opacity: 1 })
  //     })()
  //   }
  // }, [isCtaTriggered])
  useEffect(() => {
    const timer = setTimeout(async () => {
      if (!isCtaTriggered) {
        await containerControls.start({ height: 'auto' })
        await contentControls.start({ opacity: 1 })
      }
    }, 1000) // Delay the animation start by 100ms

    return () => clearTimeout(timer) // Cleanup the timeout if the component unmounts
  }, [isCtaTriggered, containerControls, contentControls])

  const triggerPageTransition = async () => {
    await contentControls.start({ opacity: 0 })
    await containerControls.start({ height: '5rem' })
  }

  const handleVerifyOnBlockchain = async () => {
    await triggerPageTransition()
    setHeaderTitle('Verifying on Blockchain...')
    setIsCtaTriggered(true)
  }

  return (
    <Box
      background="white"
      padding=".25rem"
      paddingBlock="2rem"
      borderRadius="15px"
      boxShadow="0px 4px 50px rgba(0, 0, 0, 0.10)"
    >
      <motion.div
        initial={{ height: '5rem' }}
        animate={containerControls}
        transition={{ duration: 0.3 }}
        style={{
          overflow: 'hidden',
          borderRadius: '30px',
          marginInline: '1rem',
        }}
      >
        {isCtaTriggered ? (
          <VerificationCardHeader
            id={id}
            setIsCtaTriggered={setIsCtaTriggered}
            triggerPageTransition={triggerPageTransition}
            title={headerTitle}
            containerControls={containerControls}
            studentIntegrityData={studentIntegrityData}
          >
            {headerTitle == 'Details' ? (
              ''
            ) : (
              <VerificationCardDetails
                id={id}
                studentIntegrityData={studentIntegrityData}
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                dataFetched={dataFetched}
              />
            )}
          </VerificationCardHeader>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={contentControls}
            transition={{ duration: 0.3 }}
          >
            <Box px="1rem">
              <Text fontSize="1.2rem" fontWeight="700" color="primary">
                IRMRI Certificate blockchain-based verification
              </Text>
              <Text>
                Student Enrollment No:{' '}
                {
                  studentIntegrityData?.certificateDetails
                    ?.studentenrollmentNumber
                }
              </Text>
            </Box>
            <Flex
              marginInline="1rem"
              mt={'1rem'}
              flexDirection="column"
              gap="1rem"
              alignItems="center"
            >
              <Button
                onClick={handleVerifyOnBlockchain}
                {...commonButtonStyles}
                background="#007AFF"
                color="white"
              >
                Verify on Blockchain
              </Button>
            </Flex>
          </motion.div>
        )}
      </motion.div>
    </Box>
  )
}

export default VerificationCard
