import {
  Box,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  SimpleGrid,
  Flex,
  useToast,
  Skeleton,
  Grid,
  GridItem,
  useDisclosure,
  Input,
} from '@chakra-ui/react'
import { useRef, useState, useEffect, Fragment } from 'react'
import { VisualChart } from '../components/VisualChart'
import { UploadIcon } from '../utils/Icons'
import readXlsxFile from 'read-excel-file'
import StudentService from '../apiServices/student.service'
import { validateExcelFormat } from '../utils/excelValidators'
import { UploadExcelModal } from '../components'

const Overview = () => {
  const toast = useToast()
  const inputRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [studentsGraph, setStudentsGraph] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [studentMatrices, setStudentMatrices] = useState(null)
  const [filenameForDialog, setFilenameForDialog] = useState('')
  const [metricsDataLoading, setMetricsDataLoading] = useState(false)

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }
  const handleConfirmUpload = () => {
    console.log("handleConfirmUpload")
    setIsDialogOpen(false)
    if (!inputRef?.current.files[0]) {
      toast({
        title: 'Error',
        description: 'Please select a file for upload.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }

    const fileName = inputRef.current.files[0].name
    const fileExtension = fileName.split('.').pop().toLowerCase()

    const allowedFileTypes = ['xls', 'xlsx', 'xlsm']
    if (!allowedFileTypes.includes(fileExtension)) {
      toast({
        title: 'Error',
        description:
          'Only XLS, XLSX, and XLSM file types are allowed for upload.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      })
      return
    }
    readXlsxFile(inputRef?.current.files[0]).then(async (rows) => {
      const response = await validateExcelFormat('tech', rows)
      if (response.success === false) {
        response.errors.forEach(e => console.log(e))
      }
    })
    setFilenameForDialog('')
    inputRef.current.value = null
  }

  const getMetricsData = async () => {
    try {
      setMetricsDataLoading(true)
      const [dataMetricsNew, dataGraph] = await Promise.all([
        StudentService.getStudentsMatrices(),
        StudentService.getStudentsGraph(),
      ])
      setStudentMatrices(dataMetricsNew?.data)
      setStudentsGraph(dataGraph?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setMetricsDataLoading(false)
    }
  }

  useEffect(() => {
    getMetricsData()
  }, [])

  return (
    <Fragment>
      <Flex h={'10%'} gap="2rem">
        <Flex
          direction="column"
          align="center"
          justifyContent={'center'}
          textAlign="center"
          w="150px"
          h="65px"
          bg="#C7C2FF"
          _hover={{ bg: '#C7C2FF', cursor: 'pointer' }}
          color="black"
          borderRadius={'8px'}
          htmlFor="file-upload"
          as="label"
          onClick={onOpen}
        >
          <UploadIcon />
          Upload
        </Flex>
      </Flex>

      {metricsDataLoading ? (
        <>
          <SimpleGrid
            h={'18%'}
            spacing="2"
            templateColumns="repeat(4, 1fr)"
            mt="1rem"
          >
            {[...Array(4)]?.map((_, index) => (
              <Skeleton key={index} width="322px" height="156px" />
            ))}
          </SimpleGrid>
          <Box
            h={'60%'}
            pt={3}
            display={'flex'}
            justifyContent={'center'}
            mt="3rem"
          >
            <Skeleton width="100%" height="300px" />
          </Box>
        </>
      ) : (
        <Box>
          <Grid templateColumns={'repeat(4, 1fr)'} gap={2}>
            <GridItem>
              <Card bg="#E3F5FF" boxShadow="lg" w="100%">
                <Box p="1rem">
                  <CardHeader>
                    <Text fontSize={22}>Previous Students</Text>
                  </CardHeader>
                  <CardBody>
                    <Heading size="xl">
                      {studentMatrices?.previousYearStudent || 0}
                    </Heading>
                  </CardBody>
                </Box>
              </Card>
            </GridItem>
            <GridItem>
              <Card bg="#E5ECF6" boxShadow="lg" w="100%">
                <Box p="1rem">
                  <CardHeader>
                    <Text fontSize={22}>Current Students</Text>
                  </CardHeader>
                  <CardBody>
                    <Heading size="xl">
                      {studentMatrices?.currentYearStudents || 0}
                    </Heading>
                  </CardBody>
                </Box>
              </Card>
            </GridItem>
            <GridItem>
              <Card bg="#E3F5FF" boxShadow="lg" w="100%">
                <Box p="1rem">
                  <CardHeader>
                    <Text fontSize={22}>Total Students</Text>
                  </CardHeader>
                  <CardBody>
                    <Heading size="xl">
                      {studentMatrices?.totalStudentsInSystem || 0}
                    </Heading>
                  </CardBody>
                </Box>
              </Card>
            </GridItem>
            <GridItem>
              <Card bg="#E5ECF6" boxShadow="lg" w="100%">
                <Box p="1rem">
                  <CardHeader>
                    <Text fontSize={22}>Total Certificates</Text>
                  </CardHeader>
                  <CardBody>
                    <Heading size="xl">
                      {studentMatrices?.totalCertificatesInSystem || 0}
                    </Heading>
                  </CardBody>
                </Box>
              </Card>
            </GridItem>
          </Grid>
          <Flex
            pt={3}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {studentsGraph?.length === 0 ? (
              <Text fontWeight={'bold'} mt="2rem">
                {' '}
                No graph data available
              </Text>
            ) : (
              <VisualChart height={'100%'} studentsGraph={studentsGraph} />
            )}
          </Flex>
          {/* </Flex> */}
        </Box>
      )}

      <AlertDialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Upload Confirmation</AlertDialogHeader>
          <AlertDialogBody>
            Do you want to upload the file <strong>{filenameForDialog}</strong>?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              bg="#6C3B1C"
              _hover={{ bg: '#6C3B1C' }}
              ml={3}
              onClick={handleConfirmUpload}
              color="white"
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <UploadExcelModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </Fragment>
  )
}

export default Overview
