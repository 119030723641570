import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { setAuth } from '../store/App/AppSlice'
import { jwtDecode } from 'jwt-decode'
import AuthService from '../apiServices/auth.service'
import { Box, Flex, Text, Spinner } from '@chakra-ui/react'
import routes from '../utils/routes'

const PersistLogin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const checkLoginStatus = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem('token')
      const decoded = jwtDecode(token)
      const email = decoded?.email
      const { data } = await AuthService.getAuthUser(email)
      if (data.status === true) {
        navigate(location.pathname ?? '/')
        setLoading(false)
        dispatch(setAuth({ loggedIn: true, user: data.data[0] }))
      } else {
        const response = await AuthService.refreshAccessToken()
        console.log("🚀 ~ checkLoginStatus ~ response:", response)
        const { data } = await AuthService.getAuthUser(email)
        if (data.status === true) {
          navigate(location.pathname ?? '/')
          setLoading(false)
          dispatch(setAuth({ loggedIn: true, user: data.data[0] }))
        } else {
          navigate(routes.Signin)
        }
      }
    } catch (e) {
      setLoading(false)
      navigate(routes.Signin)
    }
  }

  useEffect(() => {
    checkLoginStatus().then()
    // eslint-disable-next-line
  }, [])
  if (loading) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        backgroundColor="rgba(255, 255, 255, 0.8)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex="9999"
      >
        <Flex align="center" justify="center" flexDir="column">
          <Spinner size="xl" thickness="5px" speed="0.5s" color="#6C3B1C" />
          <Text fontWeight="bold" mt="1rem" color="#6C3B1C">
            Loading...
          </Text>
        </Flex>
      </Box>
    )
  }
  return <Outlet />
}

export default PersistLogin
