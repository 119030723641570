import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import routes from '../utils/routes'

const Home = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(routes.Overview)
  })
  return <></>
}

export default Home
