import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { selectIsLoggedIn } from '../store/App/AppSlice'

const RequireAuth = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  return isLoggedIn ? <Outlet /> : <Navigate to={'signin'} />
}

export default RequireAuth
