import React from 'react'
import { useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Divider,
} from '@chakra-ui/react'
import { CloseIcon, CheckIcon } from '@chakra-ui/icons'

function VerificationCardDetails({
  studentIntegrityData,
  steps,
  setActiveStep,
  activeStep,
  dataFetched,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeStep < steps.length) {
        setActiveStep((prevStep) => prevStep + 1)
      } else {
        clearInterval(interval)
      }
    }, 2000)

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [dataFetched, activeStep])
  return (
    <Box>
      <Box position={'relative'} p="1.5rem" fontSize="16px">
        <Stepper index={activeStep} orientation="vertical" height="500px">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={
                    step?.status ? (
                      <StepIcon />
                    ) : (
                      <CloseIcon w="20px" color="red" />
                    )
                  }
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle fontWeight="bold">{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator style={{ height: '120px' }} />
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 5 ? (
        <Button
          background="#007AFF"
          color="white"
          width="100%"
          padding="1.5rem"
          fontWeight="400"
          onClick={onOpen}
        >
          Validate Certificate
        </Button>
      ) : (
        <></>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Student Verification</ModalHeader>
          <ModalCloseButton color="#6C3B1C" />
          <ModalBody>
            <Box>
              <Flex justifyContent={'center'} alignItems="center">
                {steps?.every((step) => step.status) ? (
                  <Flex
                    justifyContent={'center'}
                    alignItems="center"
                    gap="1rem"
                  >
                    <Text color="green" fontWeight="bold">
                      Verified Successfully
                    </Text>
                    <Flex
                      // border="1px solid green"
                      // borderRadius={'16px'}
                      // width="100px"
                      // height="100px"
                      justifyContent={'center'}
                      alignItems="center"
                    // mt="1rem"
                    >
                      <CheckIcon boxSize={8} color="green" />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    justifyContent={'center'}
                    alignItems="center"
                    gap="1rem"
                  >
                    <Text fontWeight="bold" color="red">
                      Verification Failed
                    </Text>
                    <Flex
                      // border="1px solid red"
                      // borderRadius={'16px'}
                      // width="100px"
                      // height="100px"
                      justifyContent={'center'}
                      alignItems="center"
                    // mt="1rem"
                    >
                      <CloseIcon boxSize={8} color="red" />
                    </Flex>
                  </Flex>
                )}
              </Flex>
              <Divider />
              <Box p="1rem">
                <Text>Transaction hash of certificate on blockchain</Text>
                <Text fontWeight="600">
                  {studentIntegrityData?.transactionHash}
                </Text>
              </Box>
              <Button
                bg="#007AFF"
                _hover={{
                  bg: '#007AFF',
                }}
                color="white"
                borderRadius="11.26px"
                width="100%"
                p="1rem"
                onClick={() => {
                  const hostname = window.location.hostname
                  const baseTxURL = hostname.includes(
                    'staging-irmri.emertech.io'
                  )
                    ? 'https://explorer.apothem.network/txs/'
                    : 'https://polygonscan.com/tx/'
                  const url = `${baseTxURL}${studentIntegrityData?.transactionHash}`
                  window.open(url, '_blank')
                }}
                style={{ wordWrap: 'break-word' }}
              >
                <Text textWrap="wrap">Validate Transaction Hash</Text>
              </Button>
            </Box>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default VerificationCardDetails
