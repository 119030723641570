import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { Link, useParams } from 'react-router-dom'

const VerificationCardHeader = ({
  children,
  setIsCtaTriggered,
  id,
  title,
  containerControls,
  studentIntegrityData,
}) => {
  const contentControls = useAnimation()

  // useEffect(() => {
  //   (async () => {
  //     await containerControls.start({ height: 'auto' })
  //     await contentControls.start({ opacity: 1 })
  //   })()
  // }, [])

  useEffect(() => {
    const timer = setTimeout(async () => {
      await containerControls.start({ height: 'auto' })
      await contentControls.start({ opacity: 1 })
    }, 1000) // Delay the animation start by 100ms

    return () => clearTimeout(timer) // Cleanup the timeout if the component unmounts
  }, [containerControls, contentControls])

  const handleBackButtonClick = async () => {
    await containerControls.start({ height: '5rem' })
    setIsCtaTriggered(false)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={contentControls}
      transition={{ duration: 0.3 }}
    >
      <Box marginInline="1rem">
        <Box onClick={handleBackButtonClick} marginBlock={'.5rem'} mb="1rem">
          <ChevronLeftIcon ml="-0.6rem" boxSize={10} color="primary" />
        </Box>

        <Box>
          <Text color="primary" fontWeight="700">
            {title}
          </Text>
          <Text fontWeight="400" color="gray">
            Enrollment No:{' '}
            {studentIntegrityData?.certificateDetails?.studentenrollmentNumber}
          </Text>
        </Box>

        <Box>{children}</Box>
      </Box>
    </motion.div>
  )
}

export default VerificationCardHeader
