import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: { name: 'IRMRA', email: 'info@irmra.com' },
  isLoggedIn: false,
  courseId: '65b7431ae64268f272146da7',
}

const AppSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      const { user, loggedIn } = payload
      state.user = user
      state.isLoggedIn = loggedIn
    },
  },
})

export const { setAuth } = AppSlice.actions

export default AppSlice.reducer

export const selectCurrentUser = (state) => state.app.user
export const selectIsLoggedIn = (state) => state.app.isLoggedIn
export const selectCourseId = (state) => state.app.courseId
