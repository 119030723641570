import React from 'react'
import { Input, Select } from '@chakra-ui/react';

const CustomInput = ({ type, values, placeholder, data, name, handleEditInputChange, isPrev, batchName }) => {
  switch (type) {
    case "text":
      return (
        <Input
          mt={2}
          type='text'
          name={name}
          placeholder={placeholder}
          disabled={name === "batchName" && !isPrev ? true : false}
          defaultValue={name === "batchName" ? batchName : data[name] ?? ""}
          onChange={(event) => handleEditInputChange(name, event.target.value)}
        />
      )
    case "date":
      return (
        <Input
          mt={2}
          type='date'
          name={name}
          placeholder={placeholder}
          defaultValue={data[name] ?? ""}
          onChange={(event) => handleEditInputChange(name, event.target.value)}
        />
      )
    case "dropdown":
      return (
        <Select
          mt={2}
          placeholder={placeholder}
          defaultValue={data[name] ?? ""}
          onChange={(event) => handleEditInputChange(name, event.target.value)}
        >
          {values.map(option =>
            <option value={option.value}>{option.label}</option>
          )}
        </Select>
      )

    default:
      return (
        <Input
          mt={2}
          type='text'
          name={name}
          placeholder={placeholder}
          defaultValue={data[name] ?? ""}
          onChange={(event) => handleEditInputChange(name, event.target.value)}
        />
      )
  }

}

export default CustomInput