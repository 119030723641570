import React from 'react'
import routes from '../utils/routes'
import { Route, Routes } from 'react-router-dom'
import { Layout, PersistLogin, RequireAuth } from '../components'
import { Batches, ForgotPassword, Home, Overview, PageNotFound, ResetPassword, SignIn, StudentVerify, UploadForm, UploadStudents, VerifyOtp } from '../pages'

const Routing = () => {
  return (
    <Routes>
      <Route path={routes.StudentVerify} element={<StudentVerify />} />
      <Route path={routes.StudentForm} element={<UploadForm />} />
      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth />}>
          {/* protected routes */}
          <Route path={routes.Home} element={<Layout />}>
            <Route path={routes.Home} element={<Home />} />
            <Route path={routes.Overview} element={<Overview />} />
            <Route path={routes.UploadStudents} element={<UploadStudents />} />
            <Route path={routes.Batches} element={<Batches />} />
          </Route>
        </Route>
        {/* auth routes */}
        <Route path={routes.Signin} element={<SignIn />} />
        <Route path={routes.Otp} element={<VerifyOtp />} />
      </Route>
      <Route path={routes.ForgotPassword} element={<ForgotPassword />} />
      <Route path={routes.ResetPassword} element={<ResetPassword />} />
      <Route path={routes.PageNotFound} element={<PageNotFound />} />
    </Routes>
  )
}

export default Routing
