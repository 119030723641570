import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  individualStudentId: null,
}

export const IndividualSlice = createSlice({
  name: 'individualStudent',
  initialState,
  reducers: {
    setStudentId: (state, action) => {
      state.individualStudentId = action.payload
    },
  },
})

export const { setStudentId } = IndividualSlice.actions
export default IndividualSlice.reducer
export const selectIndividualId = (state) =>
  state.individualStudent.individualStudentId
