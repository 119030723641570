import React, { Fragment, useState, useEffect } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectUploadedStudents,
  updateStudent,
} from '../store/Student/StudentSlice'
import { useNavigate } from 'react-router-dom'
import StudentService from '../apiServices/student.service'
import UploadConfirmModal from './UploadConfirmModal.jsx'
import { BiEdit } from 'react-icons/bi'
import {
  camelToTitle,
  extractDataArray,
  requiredEditInputFields,
} from '../utils/constants.js'
import CustomInput from '../components/CustomInput.jsx'
import routes from '../utils/routes.js'

const UploadStudents = () => {
  const [isPrev, setIsPrev] = useState(false);
  const [prevBatch, setPrevBatch] = useState(null);
  const [courseInfo, setCourseInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const [student, setStudent] = useState(null)
  const [activeIdx, setActiveIdx] = useState(0)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onClose: confirmOnClose,
  } = useDisclosure()
  const data = useSelector(selectUploadedStudents)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0)
  const [prevBatches, setPrevBatches] = useState([])

  const fetchBatches = async () => {
    const { data } = await StudentService.getPreviousBatchesList()
    setPrevBatches(data?.data)
  }

  const handleCourseInfoChange = (key, value) => {
    setCourseInfo({ ...courseInfo, [key]: value })
  }

  const handleEditClick = (cellData) => {
    setStudent(data[cellData.row.id])
    setActiveIdx(cellData.row.id)
    onOpen()
  }

  const handleEditInputChange = (key, value) => {
    setStudent((prev) => ({ ...prev, [key]: value }))
  }


  const handleUploadStudents = async () => {
    setIsLoading(true);

    try {
      const payload = extractDataArray(localStorage.getItem('courseTag'), data, courseInfo);

      // Step 1: Create student profiles
      const creationResponse = await StudentService.createStudentsProfile({ students: payload.profile });
      if (!creationResponse.status) {
        throw new Error('Failed to create student profiles');
      }
      let _prevBatch = null
      if (isPrev) {
        // Step 2: Create batch
        const createBatchResponse = await StudentService.createStudentsBatch(payload.certificate);
        if (!createBatchResponse.status) {
          throw new Error('Failed to create student batch');
        } else {
          _prevBatch = createBatchResponse.data.data._id
        }
      }
      // Step 3: Create marksheet using the batchId from createBatchResponse
      const createMarksheetResponse = await StudentService.createMarksheet({
        students: payload.marksheet,
        courseId: courseInfo.courseId,
        batchId: isPrev ? _prevBatch : prevBatch
      });
      if (!createMarksheetResponse.status) {
        throw new Error('Failed to create marksheet');
      }

      // Success toast and navigation
      toast({
        title: 'Success',
        description: 'Students data uploaded successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
      navigate(routes.Batches);

    } catch (e) {
      // Error toast
      toast({
        title: 'Error',
        description: e?.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };


  const handleStudentUpdate = () => {
    dispatch(updateStudent({ data: student, idx: activeIdx }))
    onClose()
  }

  const columns = JSON.parse(localStorage.getItem('types')).map((row) => ({
    header: row,
    accessorKey: row,
  }))
  columns.push({
    header: 'Action',
    cell: (cell) => (
      <Button size={'sm'} bg={'#3182ce'} onClick={() => handleEditClick(cell)}>
        <BiEdit size={20} color="#fff" />
      </Button>
    ),
  })

  const [globalFiltering, setGlobalFiltering] = useState('')

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      globalFilter: globalFiltering,
    },
    onGlobalFilterChange: setGlobalFiltering,
  })

  useEffect(() => {
    fetchBatches()
  }, [])

  return (
    <Box>
      <Fragment>
        <Text as="h1" fontWeight={'bold'} fontSize="22px" textAlign="center">
          Excel Sheet Preview
        </Text>
        <Box
          display={'flex'}
          justifyContent={'left'}
          alignItems={'center'}
          mt="1rem"
        >
          <Input
            type={'text'}
            w={250}
            h={'50px'}
            fontSize={20}
            value={globalFiltering}
            onChange={(e) => {
              setGlobalFiltering(e.target.value)
            }}
            placeholder={'Search'}
          />
        </Box>
        <Table mt="1rem">
          <Tbody>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box></Box>
          <Box display={'flex'} alignItems={'center'}>
            <Box mt={2} mb={2}>
              <Button
                colorScheme="blue"
                size="sm"
                isDisabled={!table.getCanPreviousPage()}
                onClick={() => {
                  table.previousPage()
                  setActiveIndex((prev) => prev - 1)
                }}
              >
                <FaCaretLeft />
                Prev
              </Button>
              &nbsp;&nbsp;
              {table.getPageOptions().map((index) => (
                <Button
                  size="sm"
                  colorScheme={activeIndex === index ? 'blue' : 'gray'}
                  ml={1}
                  mr={1}
                  key={index}
                  onClick={() => {
                    table.setPageIndex(index)
                    setActiveIndex(index)
                  }}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="blue"
                size="sm"
                ml={2}
                isDisabled={!table.getCanNextPage()}
                onClick={() => {
                  table.nextPage()
                  setActiveIndex((prev) => prev + 1)
                }}
              >
                Next
                <FaCaretRight />
              </Button>
            </Box>
          </Box>
          <Button
            mt={2}
            mb={2}
            mr="2rem"
            bg={'#0a5c36'}
            color={'#fff'}
            onClick={() => confirmOnOpen()}
            isLoading={isLoading}
          >
            Save
          </Button>
        </Box>
      </Fragment>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {Object.entries(
                requiredEditInputFields[localStorage.getItem('courseTag')]
              ).map(([key, value]) => (
                <Box mb={5}>
                  <Text as={'label'}>{camelToTitle(key)}</Text>
                  <CustomInput
                    handleEditInputChange={handleEditInputChange}
                    data={student}
                    {...value}
                    name={key}
                  />
                </Box>
              ))}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleStudentUpdate}
              bg="#6C3B1C"
              color="white"
              mr={2}
            >
              Update
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <UploadConfirmModal
        courseInfo={courseInfo}
        isOpen={confirmIsOpen}
        onOpen={confirmOnOpen}
        onClose={confirmOnClose}
        handleInputChange={handleCourseInfoChange}
        handleUploadStudents={handleUploadStudents}
        prevBatches={prevBatches}
        isLoading={isLoading}
        setIsPrev={setIsPrev}
        isPrev={isPrev}
        setPrevBatch={setPrevBatch}
      />
    </Box>
  )
}

export default UploadStudents
