import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  uploadedStudents: [],
  currentStudents: [],
  checkedIds: [],
  checkedAll: false,
}

const StudentSlice = createSlice({
  name: 'student',
  initialState: initialState,
  reducers: {
    setUploadedStudents: (state, { payload }) => {
      state.uploadedStudents = payload
    },
    setCurrentStudents: (state, { payload }) => {
      state.currentStudents = payload
    },
    updateCurrentStudent: (state, { payload }) => {
      console.log("🚀 ~ payload:", payload)
      const { enrollmentNo } = payload
      const index = state.currentStudents.findIndex(
        (student) => student._id === enrollmentNo
      )
      if (index !== -1) {
        state.currentStudents[index].checked =
          !state.currentStudents[index].checked
        if (state.currentStudents[index].checked) {
          console.log(state.currentStudents[index]._id)
          state.checkedIds.push(state.currentStudents[index]._id)
        } else {
          console.log(state.currentStudents[index]._id)
          state.checkedIds = state.checkedIds.filter(
            (id) => id !== state.currentStudents[index]._id
          )
        }
      }
    },
    checkAllStudentCheckedStatus: (state) => {
      state.currentStudents = state.currentStudents.map((student) => {
        return { ...student, checked: !state.checkedAll }
      })
      if (state.checkedAll) {
        state.checkedIds = []
      } else {
        state.currentStudents.forEach((student) => {
          if (student.finalStatus === 'PASS' || student.finalStatus === 'ATTENDED' || student.finalStatus === 'FAIL') {
            state.checkedIds.push(student._id)
          }
        })
      }
      state.checkedAll = !state.checkedAll
    },
    updateStudent: (state, { payload }) => {
      state.uploadedStudents[payload.idx] = payload.data
    },
    clearCheckedIds: (state) => {
      state.checkedIds = []
      state.checkedAll = false
      state.currentStudents = state.currentStudents.map((student) => ({
        ...student,
        checked: false,
      }))
    },
  },
})

export const {
  setCurrentStudents,
  setUploadedStudents,
  updateStudent,
  updateCurrentStudent,
  checkAllStudentCheckedStatus,
  clearCheckedIds,
} = StudentSlice.actions

export default StudentSlice.reducer

export const selectUploadedStudents = (state) => state.student.uploadedStudents
export const selectCurrentStudents = (state) => state.student.currentStudents
export const selectCheckedIds = (state) => state.student.checkedIds
export const selectCheckedAll = (state) => state.student.checkedAll
