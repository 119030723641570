import React, { useState } from 'react'
import {
  Input,
  Box,
  Heading,
  Text,
  Button,
  Card,
  CardHeader,
  CardBody,
  useToast,
  Image,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import AuthService from '../apiServices/auth.service'
import { BiArrowBack } from 'react-icons/bi'
import { logo } from '../utils/images'
import routes from '../utils/routes'

function ResetPassword() {
  const toast = useToast()
  const { resetToken } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordVisble, setIsPasswordVisble] = useState(false)
  const userEmail = localStorage.getItem('email')

  const navigate = useNavigate()
  const onSubmitCred = async (email, password) => {
    try {
      setIsLoading(true)
      const { data: response } = await AuthService.resetPassword(email, resetToken, password)
      if (response?.status === true) {
        toast({
          title: 'Success',
          description: "Password reset has been sucessfull, Thank you!!",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
        localStorage.removeItem('email')
        navigate(routes.Signin)
        setIsLoading(false)
      } else {
        toast({
          title: 'Error',
          description: "Sorry, Something went wrong please try again later!!",
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message ?? "Sorry, Something went wrong please try again later!!",
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Card
        align="center"
        size={{ base: 'sm', md: 'md' }}
        width={{ base: 'container.xs', md: 'container.sm' }}
        padding="9"
        boxShadow="0px 3px 22px 5px rgba(237, 127, 127, 0.10), 0px 4px 4px 8px rgba(51, 207, 26, 0.05)"
      >
        <Image src={logo} w={'100px'} h={'100px'} />
        <CardHeader>
          <Heading size={{ base: 'sm', md: 'lg' }}>Reset Password</Heading>
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              email: userEmail,
              code: '',
              password: '',
            }}
            onSubmit={async (values) => {
              await onSubmitCred(values.email, values.password)
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required('Email is required'),
              // code: Yup.string()
              //   .min(6, 'Reset code must be at least 6 characters')
              //   .required('Reset code is required'),
              password: Yup.string().required('Please enter your password'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props
              return (
                <>
                  <Form>
                    <Box mt={'10px'} ml="2rem">
                      <Text
                        color={'#141D43'}
                        fontSize={{ base: 'sm', md: 'sm', lg: 'md' }}
                        fontWeight={'700'}
                        fontFamily={'font.roboto'}
                      >
                        Email{' '}
                        <Text as="span" color="red">
                          *
                        </Text>
                      </Text>
                      <Input
                        type="email"
                        color={'#141D43'}
                        w={{ base: '15rem', md: '28rem', lg: '28rem' }}
                        marginTop="10px"
                        id="email"
                        bg={'#fff'}
                        placeholder="Enter your email address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fontSize={'14px'}
                        border="1px solid #9E9E9E"
                        _placeholder={{
                          opacity: 1,
                          color: '',
                          fontSize: '14px',
                        }}
                        _focusVisible={
                          errors.email && touched.email
                            ? {
                              border: '1px solid red !important',
                            }
                            : { border: '1px solid #3958DC' }
                        }
                      />
                      {errors.email && touched.email && (
                        <Box color="red.500" marginTop={'10px'}>
                          {errors.email}
                        </Box>
                      )}
                    </Box>

                    {/* <Box mt={'10px'} ml="2rem">
                      <Text
                        color={'#141D43'}
                        fontSize={{ base: 'sm', md: 'sm', lg: 'md' }}
                        fontWeight={'700'}
                        fontFamily={'font.roboto'}
                      >
                        Reset Code{' '}
                        <Text as="span" color="red">
                          *
                        </Text>
                      </Text>
                      <Input
                        type="text"
                        color={'#141D43'}
                        w={{ base: '15rem', md: '28rem', lg: '28rem' }}
                        marginTop="10px"
                        id="code"
                        bg={'#fff'}
                        placeholder="Enter your reset code"
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fontSize={'14px'}
                        border="1px solid #9E9E9E"
                        _placeholder={{
                          opacity: 1,
                          color: '',
                          fontSize: '14px',
                        }}
                        _focusVisible={
                          errors.code && touched.code
                            ? {
                              border: '1px solid red !important',
                            }
                            : { border: '1px solid #3958DC' }
                        }
                      />
                      {errors.code && touched.code && (
                        <Box color="red.500" marginTop={'10px'}>
                          {errors.code}
                        </Box>
                      )}
                    </Box> */}

                    <Box marginTop={'10px'} position="relative" ml="2rem">
                      <Text
                        color={'#141D43'}
                        fontSize={{ base: 'sm', md: 'sm', lg: 'md' }}
                        fontWeight={'700'}
                        fontFamily={'font.roboto'}
                      >
                        Password{' '}
                        <Text as="span" color="red">
                          *
                        </Text>
                      </Text>
                      <Box>
                        <Input
                          type={isPasswordVisble ? 'text' : 'password'}
                          color={'#141D43'}
                          marginTop="10px"
                          w={{ base: '15rem', md: '28rem', lg: '28rem' }}
                          id="password"
                          bg={'#fff'}
                          zIndex="10"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          border="1px solid #9E9E9E"
                          fontSize={'14px'}
                          _placeholder={{
                            opacity: 1,
                            color: '',
                            fontSize: '14px',
                          }}
                          _focusVisible={
                            errors.password && touched.password
                              ? {
                                border: '1px solid red !important',
                              }
                              : { border: '1px solid #3958DC' }
                          }
                        />

                        <Box
                          onClick={() => {
                            if (values.password?.length <= 0) return
                            setIsPasswordVisble((prev) => !prev)
                          }}
                          style={{
                            top:
                              errors.password && touched.password
                                ? '40%'
                                : '58%',
                            opacity: values.password?.length > 0 ? 1 : 0.4,
                            cursor:
                              values.password?.length > 0
                                ? 'pointer'
                                : 'not-allowed',
                            position: 'absolute',
                          }}
                          cursor="pointer"
                          right={'1rem'}
                          zIndex="10"
                        >
                          {!isPasswordVisble ? (
                            <AiOutlineEye color={'#52BBFF'} size={24} />
                          ) : (
                            <AiOutlineEyeInvisible
                              color="#52BBFF"
                              size={24}
                              marginBottom={'10px'}
                            />
                          )}
                        </Box>
                        {errors.password && touched.password && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.password}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Button
                      ml="2rem"
                      bg="#6C3B1C"
                      color={'#fff'}
                      w={{ base: '15rem', md: '28rem' }}
                      onClick={handleSubmit}
                      marginTop={'2rem'}
                      position="relative"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Submit
                    </Button>
                  </Form>
                  <Box
                    mt={10}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <BiArrowBack style={{ marginRight: '10px' }} />
                    <Link to={'/signin'}>Back to login</Link>
                  </Box>
                </>
              )
            }}
          </Formik>
        </CardBody>
      </Card>
    </Box>
  )
}

export default ResetPassword
