import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    brand: {
      primaryColor: '#3D29D0',
      lightPurple: '#F5F3FF',
      lightGrey: '#797D8C',
      Gray17: '#2B2B2B',
      blue: '#007DFA',
      lightBlueGray: '#CCCCCC',
      lightBlue: 'B3C9FE',
      white: '#FFFFFF',
      pink: '#EA8F95',
      black: '#000000',
      gray: '#BEBEBF',
      darkYellow: '#E4A951',
      darkYellowHover: '#C58629',
      purple: '#5041BC',
      grey: '#9E9E9E',
      red: '#FA0000',
      warningYellow: '#FFCC00',
      dataIntegrityButton: '#0091FA',
      indigo: '#2D2A82',
    },
    fonts: {
      heading: 'Inter, Montserrat',
      body: 'Inter, Montserrat',
    },
  },
})

export default theme
