import { Fragment } from 'react'
import {
  Box,
  Button,
  Heading,
  Image,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

const CertificatePreview = ({ isOpen, onOpen, onClose, formRef, fileURL, enrollment, fetchedData }) => {
  return (
    <Modal size={'full'} onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody display={'flex'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
          <Box>
            <Image src='/assets/images/dummy.png' />
            <Heading
              as={'h1'}
              position={'absolute'}
              left={'51%'}
              top={'39%'}
              fontSize={45}
              transform={'translate(-50%, -50%)'}
              fontFamily={'"Dancing Script", cursive'}
            >
              {fetchedData?.name}
            </Heading>
            <Text
              fontSize={'16px'}
              position={'absolute'}
              left={'51%'}
              top={'44%'}
              transform={'translate(-50%, -50%)'}
            >
              Enrollment No. {enrollment}
            </Text>
            <Image
              w={'160px'}
              h={'160px'}
              position={'absolute'}
              right={'23%'}
              top={'37%'}
              transform={'translate(-50%, -50%)'}
              src={fileURL}
              borderRadius={5}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CertificatePreview