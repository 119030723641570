import axios from 'axios'
import AuthService from './auth.service'
import { extractPath } from '../utils/constants'

export class CustomAxios {
  static axiosInstance
  static refreshing = false

  constructor() {
    CustomAxios.setHeaders()
    CustomAxios.addRefreshAccessTokenMethod()
  }

  static setHeaders() {
    // Create an instance of Axios with default headers
    if (!CustomAxios.axiosInstance) {
      CustomAxios.axiosInstance = axios.create({
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}` || '',
          // Set other common headers here
        },
      })
    } else {
      // Update the entire headers object in the Axios instance
      CustomAxios.axiosInstance.defaults.headers = {
        ...CustomAxios.axiosInstance.defaults.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}` || '',
        // Set other common headers here
      }
    }
  }

  static addRefreshAccessTokenMethod() {
    try {
      const REFRESH_TOKEN_ROUTE = '/api/auth/refresh-accesstoken'
      const notAccessRoutes = [
        '/api/auth/refresh-accesstoken',
        '/api/auth/signin',
        '/api/auth/verify-otp',
      ]
      const axiosInstance = CustomAxios.getAxiosInstance()
      if (axiosInstance) {
        axiosInstance.interceptors.response.use(
          (response) => {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response
          },
          async function (error) {
            try {
              // Any status codes that falls outside the range of 2xx cause this function to trigger
              const originalRequest = error.config
              const route = extractPath(originalRequest.url)

              if (
                !notAccessRoutes.includes(route) &&
                route !== REFRESH_TOKEN_ROUTE &&
                error.response
              ) {
                if (error.response.status === 401 && !originalRequest._retry) {
                  originalRequest._retry = true
                  if (!CustomAxios.refreshing) {
                    try {
                      CustomAxios.refreshing = true
                      const resp = await AuthService.refreshAccessToken()
                      if (resp.status === true) {
                        // update the headers of axios
                        CustomAxios.setHeaders()
                        // remove old access token from request
                        delete originalRequest.headers.Authorization
                        // again make the old api call  with updated token

                        return axiosInstance(originalRequest).finally(() => {
                          CustomAxios.refreshing = false // Reset the refresh status
                        })
                      } else {
                        
                        CustomAxios.refreshing = false // Reset the refresh status
                        console.log("🚀 ~ CustomAxios ~ CustomAxios.refreshing:")
                        // clear localStorage if refresh token api call throw error with status code 401
                        if (resp.response.status === 401) {
                          localStorage.clear()
                          // window.location.reload()
                        }
                        return Promise.reject(resp)
                      }
                    } catch (err) {
                      console.log(
                        'an error occurred while refreshing token',
                        err
                      )
                      CustomAxios.refreshing = false // Reset the refresh status
                      localStorage.clear()
                      // window.location.reload()
                      return Promise.reject(err)
                    }
                  }
                }
              }
              // clear localStorage if refresh token api call throw error with status code 401
              else if (
                route === REFRESH_TOKEN_ROUTE &&
                error.response &&
                error.response.status === 401
              ) {
                localStorage.clear()
                // window.location.reload()
              }
              return Promise.reject(error)
            } catch (err) {
              console.log('an error occurred while refreshing token', err)
            }
          }
        )
        CustomAxios.axiosInstance = axiosInstance
      }
    } catch (err) {
      console.log('🚀 ~ CustomAxios ~ addRefreshAccessTokenMethod ~ err:', err)
      CustomAxios.refreshing = false // Reset the refresh status
      localStorage.clear()
      window.location.reload()
    }
  }

  static getAxiosInstance() {
    return CustomAxios.axiosInstance
  }
}

export const customAxios = new CustomAxios()

export default CustomAxios.getAxiosInstance
