import axios from './customAxios'
import moment from 'moment'

class StudentService {
  static async createMarksheet(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/marksheet`
    try {
      // Make a POST request using the customAxios instance
      // Include the data object in the request body for creation of a new student batch
      const response = await axios().post(url, { ...data })
      return response
    } catch (error) {
      console.error('Error uploading students:', error)
      return error // or return a custom error object/message
    }
  }

  static async createStudentsBatch(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/batch`
    try {
      // Make a POST request using the customAxios instance
      // Include the data object in the request body for creation of a new student batch
      const response = await axios().post(url, { ...data })
      return response
    } catch (error) {
      console.error('Error creating student batch:', error)
      return error // or return a custom error object/message
    }
  }

  static async createStudentsProfile(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/students`
    try {
      // Make a POST request using the axios instance
      // Include the data object in the request body for creation of a new student profile
      const response = await axios().post(url, { ...data })
      return response
    } catch (error) {
      // Handle or return the error
      console.error('Error creating student profile:', error)
      return error // or return a custom error object/message
    }
  }

  static async getPreviousBatchesList() {
    const url = `${process.env.REACT_APP_API_HOST}/api/batch`

    try {
      // Make a GET request using the customAxios instance
      // Include the data object in the request body for creation of a new student batch
      const response = await axios().get(url)
      return response
    } catch (error) {
      console.error('Error creating student batch:', error)
      return error // or return a custom error object/message
    }
  }

  static async generateCertificate(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/certificate`
    try {
      // Make a POST request using the customAxios instance
      // Include the data object in the request body for creation of a new student batch
      const response = await axios().post(url, { ...data })
      return response
    } catch (error) {
      console.error('Error creating student batch:', error)
      return error // or return a custom error object/message
    }
  }

  static async updateStudent(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/students/${data.id}`

    // Create a FormData object
    // const formData = new FormData()

    // // Append the keys and values to the FormData object
    // formData.append('name', data.name)
    // formData.append('email', data.email)
    // formData.append('image', data.image)

    const formData = new FormData()

    // Append the restStudentObj data to the FormData object
    Object.keys(data).forEach((key) => {
      if (
        ![
          'id',
          'enrollmentNo',
          'firstSem',
          'secondSem',
          'finalStatus',
          'marksheetId',
        ].includes(key)
      ) {
        formData.append(key, data[key])
      }
    })

    // Make a POST request using the customAxios instance
    // Include the email and password in the request body for authentication
    const {
      id,
      enrollmentNo,
      firstSem,
      secondSem,
      finalStatus,
      marksheetId,
      ...restStudentObj
    } = data
    // return axios().patch(url, formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //   },
    // })
    try {
      // Make a PATCH request using the customAxios instance
      // Include the data object in the request body for updation of students
      const response = await axios().patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return response
    } catch (error) {
      console.error('Error creating student batch:', error)
      return error // or return a custom error object/message
    }
  }

  static async updateMarksheets(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/marksheet/${data.id}`

    // Make a POST request using the customAxios instance
    // Include the email and password in the request body for authentication
    const payload = {
      firstSem: data.firstSem,
      secondSem: data.secondSem,
      finalStatus: data.finalStatus,
    }

    try {
      // Make a PATCH request using the customAxios instance
      // Include the data object in the request body for updation of marksheets
      const response = await axios().patch(url, { ...payload })
      return response
    } catch (error) {
      console.error('Error creating student batch:', error)
      return error // or return a custom error object/message
    }
  }

  static async getCurrentStudents(data) {
    const url = `${process.env.REACT_APP_API_HOST}/api/marksheet?batchId=${data.id}`

    // Make a POST request using the customAxios instance
    // Include the email and password in the request body for authentication
    return axios().get(url)
  }

  static async getCourses() {
    const url = `${process.env.REACT_APP_API_HOST}/api/course`

    // Make a POST request using the customAxios instance
    // Include the email and password in the request body for authentication
    return axios().get(url)
  }

  // Function to retrieve student matrices data from the API
  // Uses async/await syntax to handle asynchronous operations
  static async getStudentsMatrices() {
    // Define the API endpoint URL using the environment variable
    const url = `${process.env.REACT_APP_API_HOST}/api/students/matrices`

    try {
      // Send a GET request to the API endpoint using customAxios utility
      let response = await axios().get(url)

      if (response?.status === 200) {
        // If the response is successful, return the organization matrices data
        return response?.data
      } else {
        // Log the response data if available (not expected in a successful response)
        console.log(response)
      }
    } catch (error) {
      // Log any errors that occur during the API request
      console.log(error)
    }
  }

  // Function to retrieve student graph data from the API
  // Uses async/await syntax to handle asynchronous operations
  static async getStudentsGraph() {
    // Define the API endpoint URL using the environment variable
    const url = `${process.env.REACT_APP_API_HOST}/api/students/graph`

    try {
      // Send a GET request to the API endpoint using customAxios utility
      let response = await axios().get(url)

      if (response?.status === 200) {
        // If the response is successful, return the organization metrics data
        return response?.data
      } else {
        // Log the response data if available (not expected in a successful response)
        console.log(response)
      }
    } catch (error) {
      // Log any errors that occur during the API request
      console.log(error)
    }
  }

  /**
   * Asynchronously fetches student details from the API based on enrollment number.
   * @param {string} enrollmentNo - The enrollment number of the student to retrieve details for.
   * @returns {Promise<object>} - A promise that resolves with the student details data if successful, or an error if unsuccessful.
   */
  static async getStudentDetails(enrollmentNo) {
    // Construct the URL for the API endpoint using the provided enrollment number
    const url = `${process.env.REACT_APP_API_HOST}/api/marksheet?enrollmentNo=${enrollmentNo}`

    try {
      // Send a GET request to the API endpoint using customAxios utility
      let response = await axios().get(url)

      if (response?.status === 200) {
        // If the response is successful (status code 200), return the student details data
        return response?.data?.data
      } else {
        // Log the response data if available (not expected in a successful response)
        console.log(response)
      }
    } catch (error) {
      // Log any errors that occur during the API request
      console.log(error)
    }
  }

  /**
   * Asynchronously updates student details with the provided ID and values.
   * @param {string} id - The ID of the student whose details are to be updated.
   * @param {object} values - The updated values for the student.
   * @returns {Promise<object>} - A promise that resolves with the updated student details data if successful, or an error if unsuccessful.
   */
  static async updateStudentDetails(id, formData) {
    // Construct the URL for the API endpoint using the provided student ID
    const url = `${process.env.REACT_APP_API_HOST}/api/students/updateForm/${id}`

    try {
      // Send a GET request to the API endpoint using customAxios utility
      let response = await axios().patch(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure correct content type
        },
      })

      if (response?.status === 200) {
        // If the response is successful (status code 200), return the updated student details data
        return response?.data
      } else {
        // Log the response data if available (not expected in a successful response)
        console.log(response)
      }
    } catch (error) {
      // Return any errors that occur during the API request
      return error?.response?.data
    }
  }

  // Function to fetch student integrity data from the server using an API call
  static async fetchStudentIntegrity(id) {
    // Constructing the URL for the API endpoint with the provided student ID
    const url = `${process.env.REACT_APP_API_HOST}/api/certificate/verify/${id}`

    try {
      // Sending a GET request to the constructed URL using custom Axios instance
      let response = await axios().get(url)

      // Checking if the response status is 200 (OK)
      if (response?.status === 200) {
        // If the response status is 200, return the data received from the server
        return response?.data
      } else {
        // Log the response if status is not 200 (for debugging)
        console.log(response)
      }
    } catch (error) {
      // Log any errors that occur during the API call
      console.log(error)
    }
  }
}

export default StudentService
