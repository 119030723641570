import React, { useState, useEffect } from 'react'
import {
  Input,
  Box,
  useToast,
  Heading,
  Text,
  Button,
  Card,
  CardHeader,
  CardBody,
  Image,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import AuthService from '../apiServices/auth.service'
import { logo } from '../utils/images'
import routes from '../utils/routes'

function ForgotPassword() {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    // const isExistingUser = localStorage.getItem('isExistingUser')
    // if (!isExistingUser) {
    //   navigate(-1)
    // }
  }, [])
  const onSubmitCred = async (email) => {
    try {
      setIsLoading(true)
      const { data: response } = await AuthService.forgotPassword(email)
      if (response?.status === true) {
        toast({
          title: 'Success',
          description: "We've sent you an email with a password reset link. Please use that link to reset your password, Thank you!!",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
        localStorage.setItem('email', email)
        navigate(routes.Signin)
        setIsLoading(false)
      } else {
        toast({
          title: 'Error',
          description: "Sorry, Something went wrong please try again later!!",
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right'
        })
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message ?? "Sorry, Something went wrong please try again later!!",
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right'
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Card
        align="center"
        size={{ base: 'sm', md: 'md' }}
        width={{ base: 'container.xs', md: 'container.sm' }}
        padding="9"
        rounded="md"
        boxShadow="4px 6px 10px 0px rgba(0, 0, 0, 0.10)"
      >
        <Image src={logo} w={'100px'} h={'100px'} />
        <CardHeader >
          <Heading size={{ base: 'sm', md: 'md' }} as="b">
            Enter Email Address
          </Heading>
        </CardHeader>

        <Text size={{ base: 'xs', md: 'sm' }}>
          {' '}
          We will email you the link to reset your password
        </Text>
        <CardBody>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={async (values, { resetForm }) => {
              await onSubmitCred(values.email)
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email().required('Email is required'),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props
              return (
                <>
                  <Form>
                    <Box marginTop={'10px'} position="relative">
                      <Box>
                        <Text
                          color={'#141D43'}
                          fontSize={{ base: 'sm', md: 'sm', lg: 'md' }}
                          fontWeight={'700'}
                          fontFamily={'font.roboto'}
                        >
                          Email{' '}
                          <Text as="span" color="red">
                            *
                          </Text>
                        </Text>
                        <Input
                          type="email"
                          color={'#141D43'}
                          w={{ base: '15rem', md: '28rem', lg: '28rem' }}
                          marginTop="10px"
                          id="email"
                          bg={'#fff'}
                          placeholder="Enter your email address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fontSize={'14px'}
                          border="1px solid #9E9E9E"
                          _placeholder={{
                            opacity: 1,
                            color: '',
                            fontSize: '14px',
                          }}
                          _focusVisible={
                            errors.email && touched.email
                              ? {
                                border: '1px solid red !important',
                              }
                              : { border: '1px solid #3958DC' }
                          }
                        />
                        {errors.email && touched.email && (
                          <Box color="red.500" marginTop={'10px'}>
                            {errors.email}
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Button
                      bg="#6C3B1C"
                      color={'#fff'}
                      w={{ base: '15rem', md: '28rem' }}
                      onClick={() => handleSubmit()}
                      marginTop={'2rem'}
                      position="relative"
                      isLoading={isLoading}
                    >
                      Submit
                    </Button>
                  </Form>
                </>
              )
            }}
          </Formik>
        </CardBody>
      </Card>
    </Box >
  )
}

export default ForgotPassword
